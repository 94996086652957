// import urls from './urls'
// import locales from './locales'
// import slugs from './slugs'
// import * as errorHandler from './errorHandler'
// import device from './device'

import config from '~/config'
import { routes } from '~/router'

// import * as fileHelper from './file-helper'
const { i18n } = require('~/../next-i18next.config')

const placeholderSrc = (w: number, h: number): string => {
  return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${w} ${h}"%3E%3C/svg%3E`
}
const assetURL = (filename: string): string => {
  return `${config.assetPrefix}/${filename}`
}
const formatDisplayTime = (second: number): string => {
  const hours = Math.floor(second / 3600)
  const minutes = Math.floor((second - hours * 3600) / 60)
  const seconds = second - hours * 3600 - minutes * 60
  const ss = seconds < 10 ? '0' + seconds : second
  return hours > 0
    ? `${hours < 10 ? '0' + hours : hours}:${minutes}:${ss}`
    : `${minutes}:${ss}`
}

const kFormatter = (num: number): string => {
  return Math.abs(num) > 999 ? (num / 1000).toFixed(1) + 'k' : num + ''
}

const getURLLogin = (lng: string, uriRedirect?: string) => {
// const redirect = `${pathURL('/api/auth/signin', lng)}`
// const redirect = `${pathURL('/api/auth/signin', lng)}?redirect=${uriRedirect || window.location.href}`
  const redirect = `${pathURL('/api/auth/signin')}?redirect=${uriRedirect || window.location.href}`
  return `${config.sso.url}/login?lang=${mapLang(lng)}&serviceURL=${encodeURIComponent(redirect)}`
}

const redirectLogin = (lng: string, uriRedirect?: string): void => {
  window.location.href = getURLLogin(lng, uriRedirect)
}

const redirectSignUp = (lng: string, uriRedirect?: string): void => {
  const redirect = `${pathURL('/api/auth/signin', lng)}?redirect=${uriRedirect || window.location.href}`
  window.location.href = `${config.sso.url}/signup?lang=${mapLang(lng)}&serviceURL=${encodeURIComponent(redirect)}`
}
const redirectSignUpCreator = (lng: string): void => {
  const redirect = `${pathURL(routes.creator.reg.href, lng)}`
  window.location.href = redirect
}
const redirectLogout = (sessionId: string, lng: string): void => {
  window.location.href = `${config.sso.url
  }/logout?sessionID=${sessionId}&serviceURL=${encodeURIComponent(
    config.sso.redirect + '/' + lng,
  )}`
}
const getVideoType = (mime_type: string): string => {
  if (mime_type === 'video/mp4') {
    return 'MP4'
  }
  return 'MOV'
}

const pathURL = (path: string, lng?: string): string => {
  if (!lng || lng === i18n.defaultLocale) {
    return `${config.url.location}${path}`
  }
  if (path === '') return `${config.url.location}/${lng}`
  return `${config.url.location}/${lng}${path}`
}

const mapLang = (lng: string): string => {
  if (lng === 'ja') return 'jp'
  return lng
}

const buildQuery = (queries: object): string => {
  return Object.keys(queries).reduce((query: string, q: string): string => {
    if (queries[q]) {
      query += `${query !== '' ? '&' : ''}${q}=${queries[q]}`
    }
    return query
  }, '')
}

export interface BuildHrefProps {
  pathname: string,
  query?: Object,
  lng?: string,
}

const buildHref = ({ pathname, query, lng }: BuildHrefProps) => {
  if (!query) return pathURL(pathname, lng)

  const queryStr = Object.keys(query).reduce((r, q) => {
    const slug = `[${q}]`
    if (pathname.includes(slug)) {
      pathname = pathname.replace(slug, encodeURIComponent(query[q]))
    } else if (query[q]) {
      if (q !== 'slug') r += `${pathname.includes('?') ? '&' : r === '' ? '?' : '&'}${q}=${encodeURIComponent(query[q])}`
    }
    return r
  }, '')

  const uri = `${pathname}${queryStr}`

  if (!lng || lng === i18n.defaultLocale) return uri

  return `/${lng}${uri}`
}

const getAvatar = (profile: any) =>
  
  (profile && profile.avatar && profile.avatar !== '') ? profile.avatar : (profile.userSSO?.avatar ? (profile.userSSO.avatar.path.substring(0,14) == "profile-photos" 
  ? `${process.env.PHOTO_UPLOAD_AVATAR_SS0}${profile.userSSO.avatar.path}` : (profile.userSSO.avatar.path.substring(0,8) == "/profile" ? `${process.env.SSO_SERVER_URL}${profile.userSSO.avatar.path}` : profile.userSSO.avatar.path))
  : `${process.env.ASSET_PREFIX}/asset/image/creator/avatar/0-0${1}.jpg`)

const buildName = (profile: any, lng: string) => {
  if (profile) {
    // return `${profile.firstname} ${profile.lastname}`
    if (profile.nickname) {
      return `${profile.nickname}` || profile.email.substring(0, 1) + '*****' + profile?.email.substring(profile?.email.length - 1, profile?.email.length)
    }
    return ''
  }
}

const capitalizeFirstLetter = (string = '') => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

const CheckNoAds = (premium_info: any): boolean => {
  if (!premium_info) return false
  if (premium_info.company_id) return true
  if (
    premium_info.noads_amount > 0 ||
    (premium_info.trial_noads_expired_date &&
      Date.now() <
        new Date(
          premium_info.trial_noads_expired_date + "T23:59:00",
        ).getTime())
  ) {
    const cancel = premium_info.noads_cancelled_at
    if (
      cancel !== "" &&
      new Date(cancel).getTime() > new Date("2017-12-20T11:00:00").getTime()
    )
      return false
    return true
  }
  return false
}

export {
  placeholderSrc,
  assetURL,
  formatDisplayTime,
  kFormatter,
  redirectLogin,
  redirectLogout,
  redirectSignUp,
  redirectSignUpCreator,
  getVideoType,
  pathURL,
  mapLang,
  buildQuery,
  buildHref,
  getURLLogin,
  getAvatar,
  buildName,
  capitalizeFirstLetter,
  CheckNoAds
}
