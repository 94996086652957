import { useEffect } from 'react';

const GoogleAdsPopularCollection2PremiumPage = ({ adId }) => {
  useEffect(() => {
    if (window.googletag && window.googletag.cmd) {
      window.googletag.cmd.push(function () {
        window.googletag.display(adId);
      });
    }
  }, [adId]);

  return (
    <div
      id={adId}
      style={{
        minWidth: '200px',
        minHeight: '200px',
      }}
    ></div>
  );
};

export default GoogleAdsPopularCollection2PremiumPage;
