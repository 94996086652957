import { Col, Container, Row } from "react-bootstrap"
import React from "react"
import { Video } from "~/components/common"
import GoogleAdsKWSearchPage1 from "./Ads/GoogleAdsKWSearchPage1"
import GoogleAdsNewArrivals1Page from "./Ads/GoogleAdsNewArrivals1Page"
import GoogleAdsNewArrivals2Page from "./Ads/GoogleAdsNewArrivals2Page"
import GoogleAdsCategory1Page from "./Ads/GoogleAdsCategory1Page"
import GoogleAdsCategory2Page from "./Ads/GoogleAdsCategory2Page"
import GoogleAdsTagSearch1Page from "./Ads/GoogleAdsTagSearch1Page"
import GoogleAdsTagSearch2Page from "./Ads/GoogleAdsTagSearch2Page"
import GoogleAdsPopularCollection1Page from "./Ads/GoogleAdsPopularCollection1Page"
import GoogleAdsPopularCollection2Page from "./Ads/GoogleAdsPopularCollection2Page"
import GoogleAdsRecommend1Page from "./Ads/GoogleAdsRecommend1Page"
import GoogleAdsRecommend2Page from "./Ads/GoogleAdsRecommend2Page"
import GoogleAdsKWSearchPage1Premium from "./Ads/GoogleAdsKWSearchPage1Premium"
import GoogleAdsCategory1PremiumPage from "./Ads/GoogleAdsCategory1PremiumPage"
import GoogleAdsCategory2PremiumPage from "./Ads/GoogleAdsCategory2PremiumPage"
import GoogleAdsTagSearch1PremiumPage from "./Ads/GoogleAdsTagSearch1PremiumPage"
import GoogleAdsTagSearch2PremiumPage from "./Ads/GoogleAdsTagSearch2PremiumPage"
import GoogleAdsPopularCollection1PremiumPage from "./Ads/GoogleAdsPopularCollection1PremiumPage"
import GoogleAdsPopularCollection2PremiumPage from "./Ads/GoogleAdsPopularCollection2PremiumPage"
import GoogleAdsNewArrivals1PremiumPage from "./Ads/GoogleAdsNewArrivals1PremiumPage"
import GoogleAdsNewArrivals2PremiumPage from "./Ads/GoogleAdsNewArrivals2PremiumPage"
import GoogleAdsRecommend1PremiumPage from "./Ads/GoogleAdsRecommend1PremiumPage"
import GoogleAdsRecommend2PremiumPage from "./Ads/GoogleAdsRecommend2PremiumPage"
import { CheckNoAds } from "~/utils"

const ListVideos = ({
  videos,
  disableSearch,
  className,
  isAuthen,
  isPremium,
  meta,
  profile
}: any) => {
  if (!videos || videos.length === 0) return <></>

  const partSize = Math.ceil(videos.length / 3);
  let firstPart = videos.slice(0, partSize);
  let secondPart = videos.slice(partSize, partSize * 2);
  let thirdPart = videos.slice(partSize * 2);

  const adjustPartSize = (source, target) => {
    const remainder = target.length % 4;
    if (remainder !== 0 && source.length > 0) {
      const moveCount = 4 - remainder;
      const movedVideos = source.splice(0, moveCount);
      target.push(...movedVideos);
    }
  };
  adjustPartSize(secondPart, firstPart)
  adjustPartSize(thirdPart, secondPart)

  const renderVideoRow = (videoPart: any[], keyPrefix: string) => (
    <Row>
      {videoPart.map((item: any) => (
        <Col className="mb-3" xs={12} sm={6} md={4} lg={3} key={`${keyPrefix}-${item.id}`}>
          <Video
            data={item}
            disableSearch={disableSearch}
            users={item.users}
            isAuthen={isAuthen}
            isPremium={isPremium}
          />
        </Col>
      ))}
    </Row>
  )

  const renderAds = (ads: JSX.Element[]) => (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px", marginBottom: "20px" }}>
      {ads.map((Ad, index) => (
        <React.Fragment key={index}>{Ad}</React.Fragment>
      ))}
    </div>
  )

  const renderMetaContent = () => {
    if (meta?.search) {
      const totalVideos = videos.length;
      if (totalVideos <= 8) {
        return (
          <>
            {renderVideoRow(videos, 'search-all')}
            {renderAds(isPremium ? [
            <Container key="premium-ads" className="bg-light">
              <Row>
                <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
                  <div className='ac-d-none ac-d-lg-block'>
                    <GoogleAdsKWSearchPage1Premium adId="div-gpt-ad-1731914027933-0" />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
                  <div className='ac-d-none ac-d-lg-block'>
                    <GoogleAdsKWSearchPage1Premium adId="div-gpt-ad-1731914027933-1" />
                  </div>
                </Col>
              </Row>
            </Container>
          ] : [
            <Container key="free-ads" className="bg-light">
              <Row>
                <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
                  <div className='ac-d-none ac-d-lg-block'>
                    <GoogleAdsKWSearchPage1 adId="div-gpt-ad-1731913972869-0" />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
                  <div className='ac-d-none ac-d-lg-block'>
                    <GoogleAdsKWSearchPage1 adId="div-gpt-ad-1731913972869-1" />
                  </div>
                </Col>
              </Row>
            </Container>
          ])}
          </>
        );
      }
      
      let halfIndex = Math.ceil(totalVideos / 2);
      let firstHalf = videos.slice(0, halfIndex);
      let secondHalf = videos.slice(halfIndex);
    
      const remainder = firstHalf.length % 4;
      
      if (remainder !== 0 && secondHalf.length > 0) {
        const needToMove = 4 - remainder;
        const movedVideos = secondHalf.splice(0, needToMove);
        firstHalf = [...firstHalf, ...movedVideos];
      }
    
      return (
        <>
          {renderVideoRow(firstHalf, 'search-first')}
    
          {renderAds(isPremium ? [
            <Container key="premium-ads" className="bg-light">
              <Row>
                <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
                  <div className='ac-d-none ac-d-lg-block'>
                    <GoogleAdsKWSearchPage1Premium adId="div-gpt-ad-1731914027933-0" />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
                  <div className='ac-d-none ac-d-lg-block'>
                    <GoogleAdsKWSearchPage1Premium adId="div-gpt-ad-1731914027933-1" />
                  </div>
                </Col>
              </Row>
            </Container>
          ] : [
            <Container key="free-ads" className="bg-light">
              <Row>
                <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
                  <div className='ac-d-none ac-d-lg-block'>
                    <GoogleAdsKWSearchPage1 adId="div-gpt-ad-1731913972869-0" />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
                  <div className='ac-d-none ac-d-lg-block'>
                    <GoogleAdsKWSearchPage1 adId="div-gpt-ad-1731913972869-1" />
                  </div>
                </Col>
              </Row>
            </Container>
          ])}
    
          {secondHalf.length > 0 && renderVideoRow(secondHalf, 'search-second')}
        </>
      );
    }
    

    if (meta?.category_id) {
      const totalVideos = videos.length;
      if (totalVideos <= 8) {
        return (
          <>
            {renderVideoRow(videos, 'category-all')}
            {renderAds(isPremium ? [
            <Container key="premium-ads-1" className="bg-light">
              <Row>
                <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
                  <div className='ac-d-none ac-d-lg-block'>
                    <GoogleAdsCategory1PremiumPage adId="div-gpt-ad-1731914901502-0" />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
                  <div className='ac-d-none ac-d-lg-block'>
                    <GoogleAdsCategory1PremiumPage adId="div-gpt-ad-1731914901502-1" />
                  </div>
                </Col>
              </Row>
            </Container>
          ] : [
            <Container key="free-ads-1" className="bg-light">
              <Row>
                <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
                  <div className='ac-d-none ac-d-lg-block'>
                    <GoogleAdsCategory1Page adId="div-gpt-ad-1731914872073-0" />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
                  <div className='ac-d-none ac-d-lg-block'>
                    <GoogleAdsCategory1Page adId="div-gpt-ad-1731914872073-1" />
                  </div>
                </Col>
              </Row>
            </Container>
          ])}
          </>
        );
      }
    
      return (
        <>
          {renderVideoRow(firstPart, 'category-first')}
    
          {renderAds(isPremium ? [
            <Container key="premium-ads-1" className="bg-light">
              <Row>
                <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
                  <div className='ac-d-none ac-d-lg-block'>
                    <GoogleAdsCategory1PremiumPage adId="div-gpt-ad-1731914901502-0" />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
                  <div className='ac-d-none ac-d-lg-block'>
                    <GoogleAdsCategory1PremiumPage adId="div-gpt-ad-1731914901502-1" />
                  </div>
                </Col>
              </Row>
            </Container>
          ] : [
            <Container key="free-ads-1" className="bg-light">
              <Row>
                <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
                  <div className='ac-d-none ac-d-lg-block'>
                    <GoogleAdsCategory1Page adId="div-gpt-ad-1731914872073-0" />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
                  <div className='ac-d-none ac-d-lg-block'>
                    <GoogleAdsCategory1Page adId="div-gpt-ad-1731914872073-1" />
                  </div>
                </Col>
              </Row>
            </Container>
          ])}
    
          {renderVideoRow(secondPart, 'category-second')}
    
          {renderAds(isPremium ? [
            <Container key="premium-ads-2" className="bg-light">
              <Row>
                <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
                  <div className='ac-d-none ac-d-lg-block'>
                    <GoogleAdsCategory2PremiumPage adId="div-gpt-ad-1731914959880-0" />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
                  <div className='ac-d-none ac-d-lg-block'>
                    <GoogleAdsCategory2PremiumPage adId="div-gpt-ad-1731914959880-1" />
                  </div>
                </Col>
              </Row>
            </Container>
          ] : [
            <Container key="free-ads-2" className="bg-light">
              <Row>
                <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
                  <div className='ac-d-none ac-d-lg-block'>
                    <GoogleAdsCategory2Page adId="div-gpt-ad-1731914929592-0" />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
                  <div className='ac-d-none ac-d-lg-block'>
                    <GoogleAdsCategory2Page adId="div-gpt-ad-1731914929592-1" />
                  </div>
                </Col>
              </Row>
            </Container>
          ])}
    
          {renderVideoRow(thirdPart, 'category-third')}
        </>
      );
    }
    if (meta?.tag_name) {
      const totalVideos = videos.length;
      if (totalVideos <= 8) {
        return (
          <>
            {renderVideoRow(videos, 'tag-all')}
            {renderAds(
            isPremium
              ? [
                  <Container className="bg-light">
                    <Row>
                      <Col
                        xs={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className="ac-mb-3 ac-mb-md-0 text-center"
                      >
                        <div className="ac-d-none ac-d-lg-block">
                          <GoogleAdsTagSearch1PremiumPage adId="div-gpt-ad-1731915236085-0" />
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className="ac-mb-3 ac-mb-md-0 text-center"
                      >
                        <div className="ac-d-none ac-d-lg-block">
                          <GoogleAdsTagSearch1PremiumPage adId="div-gpt-ad-1731915236085-1" />
                        </div>
                      </Col>
                    </Row>
                  </Container>,
                ]
              : [
                  <Container className="bg-light">
                    <Row>
                      <Col
                        xs={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className="ac-mb-3 ac-mb-md-0 text-center"
                      >
                        <div className="ac-d-none ac-d-lg-block">
                          <GoogleAdsTagSearch1Page adId="div-gpt-ad-1731915204128-0" />
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className="ac-mb-3 ac-mb-md-0 text-center"
                      >
                        <div className="ac-d-none ac-d-lg-block">
                          <GoogleAdsTagSearch1Page adId="div-gpt-ad-1731915204128-1" />
                        </div>
                      </Col>
                    </Row>
                  </Container>,
                ],
            )}
          </>
        );
      }
      return (
        <>
          {renderVideoRow(firstPart, "tag-first")}
          {renderAds(
            isPremium
              ? [
                  <Container className="bg-light">
                    <Row>
                      <Col
                        xs={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className="ac-mb-3 ac-mb-md-0 text-center"
                      >
                        <div className="ac-d-none ac-d-lg-block">
                          <GoogleAdsTagSearch1PremiumPage adId="div-gpt-ad-1731915236085-0" />
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className="ac-mb-3 ac-mb-md-0 text-center"
                      >
                        <div className="ac-d-none ac-d-lg-block">
                          <GoogleAdsTagSearch1PremiumPage adId="div-gpt-ad-1731915236085-1" />
                        </div>
                      </Col>
                    </Row>
                  </Container>,
                ]
              : [
                  <Container className="bg-light">
                    <Row>
                      <Col
                        xs={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className="ac-mb-3 ac-mb-md-0 text-center"
                      >
                        <div className="ac-d-none ac-d-lg-block">
                          <GoogleAdsTagSearch1Page adId="div-gpt-ad-1731915204128-0" />
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className="ac-mb-3 ac-mb-md-0 text-center"
                      >
                        <div className="ac-d-none ac-d-lg-block">
                          <GoogleAdsTagSearch1Page adId="div-gpt-ad-1731915204128-1" />
                        </div>
                      </Col>
                    </Row>
                  </Container>,
                ],
          )}
          {renderVideoRow(secondPart, "tag-second")}
          {renderAds(
            isPremium
              ? [
                  <Container className="bg-light">
                    <Row>
                      <Col
                        xs={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className="ac-mb-3 ac-mb-md-0 text-center"
                      >
                        <div className="ac-d-none ac-d-lg-block">
                          <GoogleAdsTagSearch2PremiumPage adId="div-gpt-ad-1731915307452-0" />
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className="ac-mb-3 ac-mb-md-0 text-center"
                      >
                        <div className="ac-d-none ac-d-lg-block">
                          <GoogleAdsTagSearch2PremiumPage adId="div-gpt-ad-1731915307452-1" />
                        </div>
                      </Col>
                    </Row>
                  </Container>,
                ]
              : [
                  <Container className="bg-light">
                    <Row>
                      <Col
                        xs={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className="ac-mb-3 ac-mb-md-0 text-center"
                      >
                        <div className="ac-d-none ac-d-lg-block">
                          <GoogleAdsTagSearch2Page adId="div-gpt-ad-1731915273223-0" />
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className="ac-mb-3 ac-mb-md-0 text-center"
                      >
                        <div className="ac-d-none ac-d-lg-block">
                          <GoogleAdsTagSearch2Page adId="div-gpt-ad-1731915273223-1" />
                        </div>
                      </Col>
                    </Row>
                  </Container>,
                ],
          )}
          {renderVideoRow(thirdPart, "tag-third")}
        </>
      )
    }

    if (meta?.collection_id) {
      const totalVideos = videos.length;
      if (totalVideos <= 8) {
        return (
          <>
            {renderVideoRow(videos, 'collection-all')}
            {renderAds(isPremium ? [
            <Container className="bg-light">
            <Row>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsPopularCollection1PremiumPage adId="div-gpt-ad-1731915429479-0" />
               </div>
             </Col>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsPopularCollection1PremiumPage adId="div-gpt-ad-1731915429479-1" />
               </div>
             </Col>
           </Row>
          </Container>
          ] : [<Container className="bg-light">
            <Row>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsPopularCollection1Page adId="div-gpt-ad-1731915396596-0" />
               </div>
             </Col>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsPopularCollection1Page adId="div-gpt-ad-1731915396596-1" />
               </div>
             </Col>
           </Row>
            </Container>])}
          </>
        );
      }
      return (
        <>
          {renderVideoRow(firstPart, 'collection-first')}
          {renderAds(isPremium ? [
            <Container className="bg-light">
            <Row>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsPopularCollection1PremiumPage adId="div-gpt-ad-1731915429479-0" />
               </div>
             </Col>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsPopularCollection1PremiumPage adId="div-gpt-ad-1731915429479-1" />
               </div>
             </Col>
           </Row>
          </Container>
          ] : [<Container className="bg-light">
            <Row>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsPopularCollection1Page adId="div-gpt-ad-1731915396596-0" />
               </div>
             </Col>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsPopularCollection1Page adId="div-gpt-ad-1731915396596-1" />
               </div>
             </Col>
           </Row>
          </Container>])}
          {renderVideoRow(secondPart, 'collection-second')}
          {renderAds(isPremium ? [
            <Container className="bg-light">
            <Row>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsPopularCollection2PremiumPage adId="div-gpt-ad-1731915484320-0" />
               </div>
             </Col>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsPopularCollection2PremiumPage adId="div-gpt-ad-1731915484320-1" />
               </div>
             </Col>
           </Row>
          </Container>
          ] : [<Container className="bg-light">
            <Row>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsPopularCollection2Page adId="div-gpt-ad-1731915457363-0" />
               </div>
             </Col>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsPopularCollection2Page adId="div-gpt-ad-1731915457363-1" />
               </div>
             </Col>
           </Row>
          </Container>])}
          {renderVideoRow(thirdPart, 'collection-third')}
        </>
      )
    }

    if (meta?.sort_by) {
      const totalVideos = videos.length;
      if (totalVideos <= 8) {
        return (
          <>
            {renderVideoRow(videos, 'sort-by-all')}
          </>
        );
      }
      return (
        <>
        {renderVideoRow(firstPart, 'default-first')}
        {renderAds(isPremium ? [
            <Container className="bg-light">
            <Row>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsNewArrivals1PremiumPage adId="div-gpt-ad-1731914577393-0" />
               </div>
             </Col>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsNewArrivals1PremiumPage adId="div-gpt-ad-1731914577393-1" />
               </div>
             </Col>
           </Row>
          </Container>
          ] : [<Container className="bg-light">
            <Row>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsNewArrivals1Page adId="div-gpt-ad-1731914548009-0" />
               </div>
             </Col>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsNewArrivals1Page adId="div-gpt-ad-1731914548009-1" />
               </div>
             </Col>
           </Row>
          </Container>])}
        {renderVideoRow(secondPart, 'default-second')}
        {renderAds(isPremium ? [
            <Container className="bg-light">
            <Row>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsNewArrivals2PremiumPage adId="div-gpt-ad-1731914643595-0" />
               </div>
             </Col>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsNewArrivals2PremiumPage adId="div-gpt-ad-1731914643595-1" />
               </div>
             </Col>
           </Row>
          </Container>
          ] : [<Container className="bg-light">
            <Row>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsNewArrivals2Page adId="div-gpt-ad-1731914610403-0" />
               </div>
             </Col>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsNewArrivals2Page adId="div-gpt-ad-1731914610403-1" />
               </div>
             </Col>
           </Row>
          </Container>])}
        {renderVideoRow(thirdPart, 'default-third')}
      </>
      )
    }

    return (
      <>
        {renderVideoRow(firstPart, 'default-first')}
        {renderAds(isPremium ? [
            <Container className="bg-light">
            <Row>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsRecommend1PremiumPage adId="div-gpt-ad-1731915590504-0" />
               </div>
             </Col>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsRecommend1PremiumPage adId="div-gpt-ad-1731915590504-1" />
               </div>
             </Col>
           </Row>
          </Container>
          ] : [<Container className="bg-light">
            <Row>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsRecommend1Page adId="div-gpt-ad-1731915564543-0" />
               </div>
             </Col>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsRecommend1Page adId="div-gpt-ad-1731915564543-1" />
               </div>
             </Col>
           </Row>
          </Container>])}
        {renderVideoRow(secondPart, 'default-second')}
        {renderAds(isPremium ? [
            <Container className="bg-light">
            <Row>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsRecommend2PremiumPage adId="div-gpt-ad-1731915656180-0" />
               </div>
             </Col>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsRecommend2PremiumPage adId="div-gpt-ad-1731915656180-1" />
               </div>
             </Col>
           </Row>
          </Container>
          ] : [<Container className="bg-light">
            <Row>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsRecommend2Page adId="div-gpt-ad-1731915626884-0" />
               </div>
             </Col>
             <Col xs={12} md={6} lg={6} xl={6} className='ac-mb-3 ac-mb-md-0 text-center'>
               <div className='ac-d-none ac-d-lg-block'>
               <GoogleAdsRecommend2Page adId="div-gpt-ad-1731915626884-1" />
               </div>
             </Col>
           </Row>
          </Container>])}
        {renderVideoRow(thirdPart, 'default-third')}
      </>
    )
  }

  return <>
  {
    meta && !CheckNoAds(profile?.premium_info) ? <div className={className}>{renderMetaContent()}</div> : <Row className={className}>
    {videos.map((item: any) => (
      <Col
        className="mb-3"
        xs={12}
        sm={6}
        md={4}
        lg={3}
        xl={3}
        key={item.id}
      >
        <Video
          data={item}
          disableSearch={disableSearch}
          users={item.users}
          isAuthen={isAuthen}
          isPremium={isPremium}
        />
      </Col>
    ))}
  </Row>
  }
  </>
}

export default ListVideos
